.container {
    height: 250px;
    width: 20%;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
    background: linear-gradient(45deg, #178af5, #ecd8fc); }
    .container:hover {
      min-height: 470px;
      width: 350px;
      border-radius: 5px;
      box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
      background: linear-gradient(45deg, #fdeeee, #ecd8fc);
      margin: 0 auto; }
    .container .wrapper img {
      position: relative;
      z-index: 20;
      border-radius: 50%;
      display: block;
      height: 200px;
      width: 200px;
      border: 6px solid #fff;
      object-fit: cover;
      margin: 22px auto;
      transition: all 0.3s ease-in-out; }
    .container:hover .wrapper img.active {
      height: 470px;
      width: 350px;
      margin: 0px auto;
      border: none;
      border-radius: 5px; }
  
  .content {
    color: #fff;
    font-size: 17px;
    margin-top: 20px;
    padding: 10px 20px 10px 20px !important; }
  
  .btn {
    float: right;
    clear: both; }
  
  .secondary {
    height: 130px;
    width: 110px;
    overflow: hidden;
    display: none;
    margin: auto;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
    background: linear-gradient(45deg, #178af5, #ecd8fc); }
    .secondary:hover {
      min-height: 300px;
      width: 350px;
      border-radius: 5px;
      box-shadow: 0px 1px 35px 0px rgba(0, 0, 0, 0.3);
      background: linear-gradient(45deg, #fdeeee, #ecd8fc);
      margin: 0 auto; }
    .secondary .wrapper img {
      position: relative;
      z-index: 20;
      border-radius: 50%;
      display: block;
      height: 100px;
      width: 100px;
      border: 6px solid #fff;
      object-fit: cover;
      margin: 22px auto;
      transition: all 0.3s ease-in-out; }
    .secondary:hover .wrapper img.active {
      height: 470px;
      width: 350px;
      margin: 0px auto;
      border: none;
      border-radius: 5px; }
  
  @media only screen and (max-width: 1000px) {
    .container {
      display: none; }
    .secondary {
      display: block; }
    .intro {
      margin: auto;
      display: block; } }
  
  @media only screen and (max-width: 1100px) {
    .container:hover {
      margin-left: 10px; } }
  