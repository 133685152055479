@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap');


a {
    text-decoration: none;
}

.card-list {
    display: flex;
    padding: 3rem;    
}

.card-list::-webkit-scrollbar {
    width: 1px;
    height: 5px;
}
.card-list::-webkit-scrollbar-thumb {
    background: #dac9ff;
    border-radius: 5px;
    box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.733), inset -1px -1px 1px rgba(255, 219, 219, 0.25);
}

.card-list::-webkit-scrollbar-track {
    background: linear-gradient(90deg,#ddceff,#5d23ee 1px,#ebe3fc 0,#6790ff);
}


.card {
    max-height: fit-content;
    width: 250px;
    min-width: 250px;
    padding: 1.5rem;
    border-radius: 16px;
    background: linear-gradient(45deg, #178af5, #ecd8fc);
    display: flex;
    flex-direction: column;
    transition: .2s;
    margin: 0;
    scroll-snap-align: start;
    clear: both;
    position: relative;
}

.card:focus-within~.card, .card:hover~.card {
    transform: translateX(130px);
}

.card:hover {
    transform: translateY(-1rem);
    filter: drop-shadow(0 0 0.75rem crimson);
    color: #28242f;
}

.card:not(:first-child) {
    margin-left: -130px;
}


.card-header {
    margin-bottom: 5px;
}

/* /* .card-header p {
    font-size: 14px;
    margin: 10px;
    color: #7a7a8c;
} */

.card-header h2 {
    font-size: 20px;
    margin: .25rem 0 auto;
    text-decoration: none;
    color: inherit;
    border: 0;
    display: inline-block;
    cursor: pointer;
}

.card-header h2:hover {
    background: linear-gradient(90deg,#ff8a00,#e52e71);
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}

.card-author {
    margin: auto;
    display: grid;
    grid-template-columns: 75px 1fr;
    align-items: center;
    position: relative;
}

.author-avatar {
    grid-area: auto;
    align-self: start;
    position: relative;
    box-sizing: border-box;
}

.author-avatar img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    filter: grayscale(100%);
    display: block;
    overflow: hidden;
    margin: 16px 10px;
}

.author-name {
    grid-area: auto;
    box-sizing: border-box;
}

.author-name-prefix {
    font-style: normal;
    font-weight: 700;
    color: #7a7a8c;
}

.half-circle {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 48px;
    fill: none;
    stroke: #ff8a00;
    stroke-width: 8;
    stroke-linecap: round;
    pointer-events: none;
}

.tags {
    margin: 1rem 0 2rem;
    padding: .5rem 0 1rem;
    line-height: 2;
    margin-bottom: 0;
}

.tags a {
    font-style: normal;
    font-weight: 700;
    font-size: .5rem;
    color: #7a7a8c;
    text-transform: uppercase;
    font-size: .66rem;
    border: 3px solid #28242f;
    border-radius: 2rem;
    padding: .2rem .85rem .25rem;
    position: relative;
}

.tags a:hover {
    background: linear-gradient(90deg,#ff8a00,#e52e71);
    text-shadow: none;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    background-clip: text;
    border-color: white;
}